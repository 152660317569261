import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
// 주문할때 excel 로 export하는 기능
const OrderedProdctExcel = ({ data }) => {
  console.log("hit", data);
  const worksheet = XLSX.utils.json_to_sheet(data);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Products");

  const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
  const blob = new Blob([excelBuffer], { type: "application/octet-stream" });

  // const formData = new FormData();
  // formData.append("file", blob, "Products.xlsx");
  // return formData;
  saveAs(blob, "Products.xlsx");
};

export default OrderedProdctExcel;
