import React, { useState } from "react";
import Box from "@mui/material/Box";
import MainPageComparisonTable from "./MainPageComparisonTable";
import MainPageChart from "./MainPageChart";

// import { fontBold } from "styles/style";
import { Typography } from "@mui/material";
import { paddingTwenty } from "styles/style";

import DatePickerComponent from "layouts/salesComparision/DatePickerComponent";
import TimePickerComponent from "layouts/salesComparision/TimePickerComponent";
// import { flexCenterStyles } from "styles/style";

import dayjs from "dayjs";

import { useGetTotalDailySalesByStores } from "api/comparison";

import { chartTitle, dateTimePicker, timePicker } from "./MainPageStyle";
import { useGetSameDaysOfListInMonth } from "hooks/hook";

import SideNavbar from "layouts/sidenavbar/SideNavbar";
const MainPageComponent = () => {
  const initalDate = dayjs().subtract(1, "day");
  const intialSTime = dayjs()
    .set("hour", 8)
    .set("minute", 0)
    .set("second", 0)
    .set("millisecond", 0);

  const intialETime = dayjs()
    .set("hour", 23)
    .set("minute", 0)
    .set("second", 0)
    .set("millisecond", 0);

  const [selectedDate, setSelectedDate] = useState(initalDate);
  const [startTime, setStartTime] = useState(intialSTime);
  const [endTime, setEndTime] = useState(intialETime);

  const { currentMonthSList, prevMonthSList, prevYearSList } =
    useGetSameDaysOfListInMonth({ selectedDate });

  const { dailyTotalSales, isLoading } = useGetTotalDailySalesByStores(
    selectedDate,
    startTime,
    endTime,
    currentMonthSList,
    prevMonthSList,
    prevYearSList
  );

  // if (isLoading) {
  //   return <div>Loading...</div>;
  // }

  return (
    <Box>
      <SideNavbar />
      <Box sx={paddingTwenty}>
        <Typography sx={chartTitle}>Comparison Analysis of Sales</Typography>
        <Box>
          <Box sx={{ ...dateTimePicker }}>
            <DatePickerComponent
              lable="Date"
              date={selectedDate}
              setSelectedDate={setSelectedDate}
            />
            <Box sx={timePicker}>
              <TimePickerComponent
                label="Start Time"
                time={startTime}
                setTime={setStartTime}
                compareTime={endTime}
                isStart={true}
              />
              <Typography>to</Typography>
              <TimePickerComponent
                label="End Time"
                time={endTime}
                setTime={setEndTime}
                compareTime={startTime}
                isStart={false}
              />
            </Box>
          </Box>

          {dailyTotalSales !== undefined && (
            <>
              <MainPageChart dailyTotalSales={dailyTotalSales} />
              <Box xs={12} md={10}>
                <MainPageComparisonTable
                  isClickable={false}
                  dailyTotalSales={dailyTotalSales}
                  selectedDate={selectedDate}
                  startTime={startTime}
                  endTime={endTime}
                />
              </Box>
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default MainPageComponent;
