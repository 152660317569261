import { atom } from "recoil";

// 글로벌 변수를 관리하는(유저관리)
export const userAtom = atom({
  key: "userAtom",
  default: {
    isAuthenticated: true,
    userEmail: null,
    mainC: "2",
    subC: "gfc",
  },
});
