import {
  Box,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { tableTopDownSize } from "styles/style";
const VendorReportExtendedTable = (props) => {
  const { items } = props;
  const [extendTableOpen, setExtendTableOpen] = useState(false);

  return (
    <>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <IconButton
          aria-label="expand row"
          size="small"
          onClick={() => setExtendTableOpen(!extendTableOpen)}
        >
          {extendTableOpen ? (
            <KeyboardArrowUpIcon />
          ) : (
            <KeyboardArrowDownIcon />
          )}
        </IconButton>
        <TableCell sx={tableTopDownSize}>{items.pName}</TableCell>
        <TableCell align="center" sx={tableTopDownSize}>
          {items.totalQuantity}
        </TableCell>
        <TableCell align="right" sx={tableTopDownSize}>
          {items.totalAmount.toFixed(2)}
        </TableCell>
        <TableCell></TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={extendTableOpen} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                세부품목
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableBody>
                  <TableRow>
                    <TableCell>상품이름</TableCell>
                    <TableCell align="center">밴더코드</TableCell>
                    <TableCell align="right">합계</TableCell>
                    <TableCell align="right">총 가격 ($)</TableCell>
                  </TableRow>

                  {Object.entries(Object.entries(items)[1][1]).map(
                    ([key, group], index) => (
                      <TableRow key={group.prodKname + index + key}>
                        <TableCell component="th" scope="row">
                          {group.prodKname}
                        </TableCell>
                        <TableCell align="center">{group.vp_vdpdcd}</TableCell>
                        <TableCell align="right">{group.totalQ}</TableCell>
                        <TableCell align="right">
                          {group.totalA.toFixed(2)}
                        </TableCell>
                      </TableRow>
                    )
                  )}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default VendorReportExtendedTable;
