import axios from "axios";

export default class DbManager {
  static mURL = "/api";

  static async POST(url, body) {
    try {
      const response = await axios.post(this.mURL + url, body, {
        credentials: "include",
      });

      if (response.status === 201 || response.status === 200) {
        return response.data;
      } else if (response.status === 301) {
        console.log(response.status);
      } else {
        console.log(response.status);
      }
    } catch (error) {
      console.log("error", error);
      // if (error.response && error.response.status === 401) {
      //   alert("Invalid email or password");
      // } else {
      //   alert("An error occurred. Please try again later.");
      // }
      // console.error(`HTTP error!`, error.response || error);
      // throw error;
    }
  }

  static async GET(url, params) {
    try {
      const response = await axios.get(this.mURL + url, {
        params: params,
        credentials: "include",
      });
      if (response.status === 200) {
        return response.data;
      } else if (response.status === 301) {
        console.log(response.status);
      } else {
        console.log(response);
        // throw new Error(`Unexpected response status: ${response.status}`);
      }
    } catch (error) {
      console.log("error", error);
      // if (error.response && error.response.status === 401) {
      //   alert("Invalid email or password");
      // } else {
      //   alert("An error occurred. Please try again later.");
      // }
      // console.error(`HTTP error!`, error.response || error);
      // throw error;
    }
  }
}
