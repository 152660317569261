import { Box, Button, TextField } from "@mui/material";
import DbManager from "globalApi/DbManager";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { flexCenterStyles } from "styles/style";
import { placeAtTheCenter } from "styles/style";
import { loginButton, loginForm, loginLabel, userInput } from "./LoginStyle";
import { useRecoilState } from "recoil";
import { userAtom } from "atoms/userAtom";
import { jwtDecode } from "jwt-decode";
const LoginHmart = () => {
  const [userEmail, setUseremail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [userStatus, setUserStatus] = useRecoilState(userAtom);
  const token = "hanascom";
  const navigate = useNavigate();

  // const getCookie = (name) => {
  //   const value = `; ${document.cookie}`;

  //   const parts = value.split(`; ${name}=`);
  //   console.log(parts.pop().split(";").shift());
  //   if (parts.length === 2) return parts.pop().split(";").shift();
  //   return null;
  // };

  const handleIputChange = (event) => {
    const { name, value } = event.target;

    if (name === "userEmail") {
      setUseremail(value);
    } else if (name === "password") {
      setPassword(value);
    }
  };

  const handleLogin = async (event) => {
    event.preventDefault();
    if (!emailPattern.test(userEmail)) {
      setErrorMessage("Invalid email format");
      return;
    } else {
      setErrorMessage("");
    }

    // main 1 : vancouver Hmart,
    // main 2 : Toronto Hmart,
    // main 3 : Seattle Hmart,
    // main 4 : Oregon Hmart,
    // main 5 : Denver Hmart,
    // main 7 : Utah Hmart,
    // main 8 : Montreal Hmart
    const result = await DbManager.POST("/employee/user/login", {
      userEmail: userEmail,
      password: password,
      // signup 할때 사용하는
      // mainC: "",
      // mainC: "2",
      // subC: "egp",
    });

    if (result) {
      const { email, mainC, subC } = result.data;
      setUserStatus({
        isAuthenticated: true,
        email: email,
        mainC: mainC,
        subC: subC,
      });
      if (mainC === "1") {
        navigate("/");
      } else if (mainC === "2") {
        navigate(`/vendors/${subC}`);
      } else {
        alert("Please check your userEmail");
      }
    }
  };
  const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  const handleInputChange = (e) => {
    const value = e.target.value;
    setUseremail(value);
  };
  return (
    <Box
      sx={{
        ...placeAtTheCenter,
      }}
    >
      <Box sx={{ ...flexCenterStyles, ...loginLabel }}>Sign In</Box>
      <Box sx={{ ...flexCenterStyles, ...loginForm }}>
        <TextField
          InputProps={{ sx: userInput }}
          type="email"
          name="email"
          placeholder="email"
          value={userEmail}
          onChange={handleInputChange}
          error={!!errorMessage}
          helperText={errorMessage}
        />
        <TextField
          InputProps={{ sx: userInput }}
          type="password"
          name="password"
          placeholder="Password"
          value={password}
          onChange={handleIputChange}
        />
        <Button
          onClick={handleLogin}
          sx={{ ...flexCenterStyles, ...loginButton }}
        >
          LOGIN
        </Button>
      </Box>
    </Box>
  );
};

export default LoginHmart;
