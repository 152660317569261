import { toast } from "material-react-toastify";

//에러메시지를 받아서 toast 로 만드는 기능
export const userInputErrorToaster = (text) =>
  toast.warn(`${text}`, {
    position: "bottom-center",
  });

//리스트를 받아서 categoryN와 totalQt를 각각(prodName, prodQauntity)로 넣어주는 기능
export const productNameNQauntityList = (vendorInfo) => {
  vendorInfo.map((item) => {
    return { prodName: item.pName, prodQauntity: item.totalQ };
  });
};

//데이터리스트에서 중복된 값들을 삭제하는 기능
export const removeDuplicateItemFromData = (dataList, sortList) => {
  return [...new Set(dataList.map((item) => item[sortList]))];
};

//적절한 입력값이 없을때 에러메시지 (toaster)
export const errorHanglingWithoutStoreNDepart = (dataList) => {
  if (Object.keys(dataList).length === 0) {
    userInputErrorToaster("설정하신기간안에 선택된 아이템이없습니다");
  } else {
    userInputErrorToaster(
      "아직입력되지 않은 입력값이 있습니다. 기입하지 않은 입력값을 넣어주세요"
    );
  }
};
