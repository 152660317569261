import {
  Box,
  Checkbox,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import CurrentPriceTextFiled from "./CurrentPriceTextFiled";
import { flexCenterStyles } from "styles/style";
import NumberCounter from "./NumberCounter";
const OrderPageExtendedTable = (props) => {
  const {
    values,
    currentOrderTable,
    setCurrentOrderTable,
    titleIndex,
    largeToggleCheckbox,
    smallToggleCheckbox,
  } = props;
  const [extendTableOpen, setExtendTableOpen] = useState(false);
  return (
    <>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <IconButton
          aria-label="expand row"
          size="small"
          onClick={() => setExtendTableOpen(!extendTableOpen)}
        >
          {extendTableOpen ? (
            <KeyboardArrowUpIcon />
          ) : (
            <KeyboardArrowDownIcon />
          )}
        </IconButton>
        <TableCell>
          <Checkbox
            checked={values.select}
            onChange={() => largeToggleCheckbox(titleIndex)}
          />
        </TableCell>
        <TableCell>{values.pName}</TableCell>
        <TableCell>{values.totalQuantity}</TableCell>
        <TableCell>{values.totalAmount.toFixed(2)}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={extendTableOpen} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                세부품목
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableBody>
                  <TableRow>
                    <TableCell align="center">선택</TableCell>
                    <TableCell align="center">밴더제품코드</TableCell>
                    <TableCell>상품이름</TableCell>

                    <TableCell>총수량</TableCell>
                    {/* <TableCell align="center">단위당 가격</TableCell> */}
                    <TableCell align="center">총 가격 ($)</TableCell>
                  </TableRow>
                  {/* {console.log(Object.entries(values)[1][1])} */}
                  {Object.entries(Object.entries(values)[1][1]).map(
                    ([key, group], index) => (
                      <TableRow key={group.prodKname + index + key}>
                        <TableCell align="center">
                          <Checkbox
                            checked={group.select}
                            onChange={() =>
                              smallToggleCheckbox(titleIndex, index)
                            }
                          />
                        </TableCell>
                        <TableCell align="center">{group.vp_vdpdcd}</TableCell>
                        <TableCell component="th" scope="row">
                          {group.prodKname}
                        </TableCell>

                        {/* <TableCell align="right">{group.totalQ}</TableCell> */}

                        {/* <TableCell align="center">
                          <NumberCounter
                            currentNum={group.totalQ}
                            currentOrderTable={currentOrderTable}
                            setCurrentOrderTable={setCurrentOrderTable}
                            index={index}
                          ></NumberCounter>
                        </TableCell>
                        <TableCell align="center">
                          <CurrentPriceTextFiled
                            currentOrderTable={currentOrderTable}
                            setCurrentOrderTable={setCurrentOrderTable}
                            // currentPrice={row.currentPrice}

                            index={index}
                          />
                        </TableCell> */}
                        <TableCell>{group.totalQ}</TableCell>
                        <TableCell align="center">
                          {group.totalA.toFixed(2)}
                        </TableCell>
                      </TableRow>
                    )
                  )}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default OrderPageExtendedTable;
