import zIndex from "@mui/material/styles/zIndex";

export const sidebar = {
  height: "50px",
  position: "fixed",

  display: "flex",
  width: "100vw",
  padding: "20px",
  background: "linear-gradient(195deg, #42424a, #191919)",
  color: "#ffffff",
  justifyContent: "space-around",
  zIndex: "1",
};

export const logoImage = {
  width: "50px",
  height: "50px",
  marginRight: "15px",
};

export const sideMenuContainder = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
};

export const navIcons = {
  display: "none",
  "@media (min-width: 800px)": {
    display: "block",
  },
};

export const sideMenuItems = {
  default: {
    display: "flex",
    alignItems: "center",
    gap: "1rem",
    position: "relative",
    transition: "all 300ms ease",
    fontSize: "15px",
    padding: "12px 10px 12px 10px",
    margin: "0 5px 0 5px",
    borderRadius: "0.375rem",
    "&:hover": {
      cursor: "pointer",
      background: "#767676",
    },
  },
  active: {
    background: "linear-gradient(195deg, #49a3f1, #1a73e8)",
  },
};
