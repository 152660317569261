import DbManager from "globalApi/DbManager";
import { USSTORE } from "globalApi/EnumType";

import { useEffect, useState } from "react";

export const useVendorsData = (vendor) => {
  const [stores, setStores] = useState([]);
  const [wholeData, setWholeData] = useState([]);
  const [menuLoading, setMenuLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      const getVendorsData = await DbManager.GET(
        `/achieve/vendorsStoreNDepart?vendor=${vendor}`
      );

      if (getVendorsData) {
        const groupedData = getVendorsData.reduce((acc, curr) => {
          const { storeN, ptName, vendor_name } = curr;

          if (!acc[storeN]) {
            acc[storeN] = {
              storeN,
              vendor_name,
              ptNames: [],
            };
          }

          acc[storeN].ptNames.push(ptName);

          return acc;
        }, {});

        const storeList = Object.keys(groupedData);
        const storeData = storeList.map((storeN) => USSTORE[storeN][0]);

        setWholeData(groupedData);
        setStores(storeData);

        setMenuLoading(false);
      } else {
        console.error("no data received");
      }
    };

    fetchData();
  }, [vendor]);

  return { stores, wholeData, menuLoading };
};

export const useVendorsStoreDepartmentsData = (store, wholeData) => {
  const [storesDepartmentsList, setStoresDepartmentsList] = useState([]);
  useEffect(() => {
    const getTheDepartmentList = () => {
      if (store !== "" && wholeData !== "") {
        const findStoreKeyByName = (storeName, storeData) => {
          const entry = Object.entries(storeData).find(([key, names]) =>
            names.includes(storeName)
          );
          return entry ? entry[0] : null;
        };

        const key = findStoreKeyByName(store, USSTORE);
        setStoresDepartmentsList(wholeData[key].ptNames);
      }
    };
    getTheDepartmentList();
  }, [store, wholeData]);

  return { storesDepartmentsList };
};

export const useGetVendorsProdInfoByUserSelection = (vendor) => {
  const [data, setData] = useState([]);
  const [totalSalesNQt, setTotlaSalesNQt] = useState(0);
  const [prodInfoloading, setProdInfoLoading] = useState(false);

  const fetchVendorProdInfo = async (
    store,
    department,
    convertedStartDate,
    convertedEndDate
  ) => {
    setProdInfoLoading(true);
    const findStoreKeyByName = (storeName, storeData) => {
      const entry = Object.entries(storeData).find(([key, names]) =>
        names.includes(storeName)
      );
      return entry ? entry[0] : null;
    };

    const key = findStoreKeyByName(store, USSTORE);

    const retrieveData = await DbManager.GET(
      `/achieve/vendors?store=${key}&department=${department}&vendor=${vendor}&startDate=${convertedStartDate}&endDate=${convertedEndDate}`
    );

    setData(retrieveData);

    // 조건에 맞춰서 온 데이터값들을 각각 아이템 가격과 총수량을 계산한 부분
    // const accumulateByItem = accumulateByItems(retrieveData);
    // 위에 각각의 아이템 가격과 총수량을 통해서 전체 아이템 수량및 전체 매출을 합산하는 부분
    // const entireAccumulatedTotal = cacluateTotals(accumulateByItem);

    // setTotlaSalesNQt(entireAccumulatedTotal);
    // setData(Object.values(accumulateByItem));
    // 벤더리포트 테이블에서 사용하기 쉽게 data reform 하는 과정

    setProdInfoLoading(false);
    //   }
  };
  return { data, prodInfoloading, totalSalesNQt, fetchVendorProdInfo };
};
