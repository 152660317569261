import ProfileIndIcon from "@mui/icons-material/AssignmentInd";
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import LoginIcon from "@mui/icons-material/Login";

export const SidebNavlist = [
  {
    icon: DashboardOutlinedIcon,
    heading: "Dashboard",
  },
  {
    icon: ProfileIndIcon,
    heading: "Profile",
  },

  // {
  //   icon: LoginIcon,
  //   heading: "Login",
  // },
];
