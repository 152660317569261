import { STORE } from "globalApi/EnumType";
import { useEffect, useState } from "react";
import { sameDaysDatList } from "utils/utils";

export const useGetSameDaysOfListInMonth = ({ selectedDate }) => {
  const [currentMonthSList, setCurrentMonthSList] = useState();
  const [prevMonthSList, setPrevMonthSList] = useState();
  const [prevYearSList, setPrevYearSList] = useState();
  useEffect(() => {
    const getTheData = () => {
      const convertedPacificDate = new Date(selectedDate);

      let prevMonthDate = new Date(selectedDate);
      prevMonthDate.setMonth(prevMonthDate.getMonth() - 1);
      let prevYearDate = new Date(selectedDate);
      prevYearDate.setFullYear(prevYearDate.getFullYear() - 1);

      // //같은달에 같은요일에 대한 리스트
      const currentMonthSameDateList = sameDaysDatList(
        convertedPacificDate,
        convertedPacificDate.getDay(),
        true
      );
      setCurrentMonthSList(currentMonthSameDateList);

      // //전달의 같은요일에 대한 리스트
      const prevMonthSameDateList = sameDaysDatList(
        prevMonthDate,
        convertedPacificDate.getDay(),
        false
      );
      setPrevMonthSList(prevMonthSameDateList);

      const prevYearSameDateList = sameDaysDatList(
        prevYearDate,
        convertedPacificDate.getDay(),
        false
      );
      setPrevYearSList(prevYearSameDateList);
    };
    getTheData();
  }, [selectedDate]);
  return { currentMonthSList, prevMonthSList, prevYearSList };
};

export const useConvertCodeToName = ({ storeIntial }) => {
  const [storeNum, setStoreNum] = useState();
  useEffect(() => {
    const getStoreNameByCode = () => {
      for (const [key, [name, code]] of Object.entries(STORE)) {
        if (code === storeIntial) {
          setStoreNum(key);
          // return key;
        }
      }
      return "";
    };

    getStoreNameByCode();
  }, [storeIntial]);

  return { storeNum };
};

export const useCalculateAllTotal = ({ dailyTotalSales }) => {
  const [calculatedTotals, setCalculatedTotals] = useState();

  useEffect(() => {
    if (dailyTotalSales && Object.keys(dailyTotalSales).length > 0) {
      const calculateTotal = Object.values(dailyTotalSales).reduce(
        (acc, curr) => {
          Object.keys(curr).forEach((key) => {
            acc[key] = (acc[key] || 0) + curr[key];
          });
          return acc;
        },
        {}
      );
      //같은 값이 있을때 계속 실행되는걸 방지하기 위해서 넣은 부분
      setCalculatedTotals((prevTotals) => {
        if (JSON.stringify(prevTotals) !== JSON.stringify(calculateTotal)) {
          return calculateTotal;
        }
        return prevTotals;
      });
    }
  }, [dailyTotalSales]);

  return { calculatedTotals };
};
