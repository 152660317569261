import { STORE } from "globalApi/EnumType";

// 데이터 자체를 initalizing해놓고(매출자체가 존재하지 않을수도 있어서) 각각에 데이터를 집어넣는 함수
// 현재는 밑에 데이터 집어넣는부분에 함수자체를 하나 만들어야할거같음
export const insertDataIntoObject = async (
  yesterDayTotalS,
  currentMonthAver,
  prevMonthAver,
  prevYearAver
) => {
  let storeDataInitalize = Object.fromEntries(
    Object.keys(STORE).map((key) => [
      key,
      {
        yesterDaySales: 0,
        currentMAverage: 0,
        lastYearAverage: 0,
        lastMonthAverage: 0,
        comparedToCurrentMAverage: 0,
        comparedToLastYAverage: 0,
        comparedToLastMAverage: 0,
      },
    ])
  );
  yesterDayTotalS.forEach((ydata) => {
    storeDataInitalize[ydata.store].yesterDaySales = ydata.sales;
  });

  currentMonthAver.forEach((currneMAver) => {
    storeDataInitalize[currneMAver.store].currentMAverage = currneMAver.average;
    storeDataInitalize[currneMAver.store].comparedToCurrentMAverage =
      storeDataInitalize[currneMAver.store].yesterDaySales -
      currneMAver.average;

    // }
  });
  prevMonthAver.forEach((prevM) => {
    storeDataInitalize[prevM.store].lastMonthAverage = prevM.average;
    storeDataInitalize[prevM.store].comparedToLastMAverage =
      storeDataInitalize[prevM.store].yesterDaySales - prevM.average;
  });
  prevYearAver.forEach((prvY) => {
    storeDataInitalize[prvY.store].lastYearAverage = prvY.average;
    storeDataInitalize[prvY.store].comparedToLastYAverage =
      storeDataInitalize[prvY.store].yesterDaySales - prvY.average;
  });

  return storeDataInitalize;
};
