import React, { useState } from "react";
import {
  Box,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

import dayjs from "dayjs";
// import VendorReportExportExcel from "utils/VendorReportExportExcel";
import { convertDateForm } from "utils/utils";
import { venderTableTitle } from "./VendorReportPageStyle";
import { whiteFont } from "styles/style";
import { flexSpaceBetween } from "styles/style";
import { tableTopDownSize } from "styles/style";
import VendorReportExtendedTable from "./VendorReportExtendedTable";

const VendorReportTable = ({
  selectedData,
  startDate,
  endDate,
  totalSalesNQt,
  groupedData,
  loading,
}) => {
  const tableCellTitle = ["제품타입", "제품총수량", "제품총합"];
  const [extendTableOpen, setExtendTableOpen] = useState(false);
  return (
    <Box>
      <Box sx={venderTableTitle}>
        <strong>기간별 Group 매출액 집계현황</strong>
      </Box>
      <Box sx={flexSpaceBetween}>
        {Object.keys(groupedData).length === 0 ? (
          <></>
        ) : (
          <>
            <Box sx={{ marginLeft: "8vw" }}>
              조회기간: {convertDateForm(dayjs(selectedData.startDate))} -{" "}
              {convertDateForm(dayjs(selectedData.endDate))}
            </Box>
          </>
        )}
      </Box>

      <TableContainer
        variant="soft"
        sx={{
          pt: 1,
          borderRadius: "sm",
          width: "90%",
          marginLeft: "auto",
          marginRight: "auto",
        }}
        component={Paper}
      >
        <Table stripe="odd" aria-label="collapsible table">
          <TableHead>
            <TableRow
              sx={{
                backgroundColor: "#129d58",
              }}
            >
              <TableCell></TableCell>
              {tableCellTitle.map((tableCell, index) => (
                <>
                  <TableCell
                    align={
                      index === 1 ? "center" : index === 2 ? "right" : "left"
                    }
                    key={tableCell + index}
                    sx={{ ...whiteFont, ...tableTopDownSize }}
                  >
                    {/* {tableCell} */}
                  </TableCell>
                </>
              ))}
              <TableCell></TableCell>
            </TableRow>
          </TableHead>

          {loading === true ? (
            <></>
          ) : (
            <>
              {Object.entries(groupedData).map(([key, group], index) => (
                <>
                  <TableBody key={key + index}>
                    <VendorReportExtendedTable items={group} />
                  </TableBody>
                </>
              ))}
            </>
          )}
        </Table>
      </TableContainer>
    </Box>
  );
};

export default VendorReportTable;
