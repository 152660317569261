import React, { useState } from "react";
import Button from "@mui/material/Button";
import dayjs from "dayjs";
import { Box } from "@mui/material";
import SelectButton from "buttons/SelectButton";
import DateSelector from "buttons/DateSelector";
import VendorReportTable from "./VendorReportTable";
import { convertDateForm } from "utils/utils";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "material-react-toastify";
import "material-react-toastify/dist/ReactToastify.css";
import {
  vendorReportContainer,
  vendorButton,
  vendorSearchButtonNorderContainer,
  vendorSearchButton,
  vendorReportContainerBox,
} from "./VendorReportPageStyle";
import {
  errorHanglingWithoutStoreNDepart,
  userInputErrorToaster,
} from "./VendorReportUtils";
import { useVendorsData } from "api/vendorReport";
import { useGetVendorsProdInfoByUserSelection } from "api/vendorReport";
import { useVendorsStoreDepartmentsData } from "api/vendorReport";
import SideNavbar from "layouts/sidenavbar/SideNavbar";

const VendorReportPageComponent = ({ vendor }) => {
  const [vendorInfo, setVendorInfo] = useState([]);

  const [store, setStore] = useState("");

  const [prodInfoloading, setProdInfoloading] = useState(true);

  const [department, setDepartment] = useState("");
  const [startDate, setStartDate] = useState(dayjs());
  const [endDate, setEndDate] = useState(dayjs());
  const [selectedData, setSelectedData] = useState({});

  // 리액트 Hook 을 사용해서 api request에 관련된 로직을 넣어놨습니다
  const { stores, wholeData, menuloading } = useVendorsData(vendor);
  const { storesDepartmentsList } = useVendorsStoreDepartmentsData(
    store,
    wholeData
  );

  const { data, totalSalesNQt, fetchVendorProdInfo } =
    useGetVendorsProdInfoByUserSelection(vendor);
  const navigate = useNavigate();

  const groupedData = data.reduce((acc, curr) => {
    const { pName, vp_vdpdcd, totalQ, totalA, prodKname } = curr;

    // pName 기준으로 그룹이 존재하지 않으면 초기화
    if (!acc[pName]) {
      acc[pName] = {
        pName,
        items: [],
        totalAmount: 0,
        totalQuantity: 0,
      };
    }

    if (!acc[pName].items[vp_vdpdcd]) {
      acc[pName].items[vp_vdpdcd] = {
        vp_vdpdcd,
        prodKname,
        totalQ: 0,
        totalA: 0,
      };
    }

    // 하위 그룹 데이터 누적
    acc[pName].items[vp_vdpdcd].totalQ += totalQ;
    acc[pName].items[vp_vdpdcd].totalA += totalA;

    // 상위 그룹 총계 누적
    acc[pName].totalQuantity += totalQ;
    acc[pName].totalAmount += totalA;

    return acc;
  }, {});

  const getTheDataBasedOnUserInput = async (e) => {
    e.preventDefault();
    const convertedStartDate = convertDateForm(startDate);
    const convertedEndDate = convertDateForm(endDate);

    if (store === "" && department === "") {
      errorHanglingWithoutStoreNDepart(vendorInfo);
    } else {
      fetchVendorProdInfo(
        store,
        department,
        convertedStartDate,
        convertedEndDate
      );

      const selectData = {
        store: store,
        department: department,
        startDate: startDate,
        endDate: endDate,
      };
      setSelectedData(selectData);

      setProdInfoloading(false);
    }
  };

  //주문페이지로 넘어갈때 선택된 아이템이없으면 toaster event 발생 아니면 다음페이지인 주문페이지로 이동
  const linkToOrderPage = (e) => {
    e.preventDefault();

    if (Object.keys(groupedData).length !== 0) {
      navigate("order", { state: groupedData });
    } else {
      userInputErrorToaster("설정하신기간안에 선택된 아이템이없습니다");
    }
  };

  return (
    <>
      <SideNavbar />
      <Box>
        <Box sx={vendorReportContainerBox}>
          <Box sx={vendorReportContainer}>
            {menuloading === true ? (
              <></>
            ) : (
              <>
                <Box sx={vendorButton}>
                  <Box sx={vendorButton}>
                    <SelectButton
                      setData={setStore}
                      selected={store}
                      title={"Store"}
                      data={stores}
                    />
                    {storesDepartmentsList !== undefined && (
                      <>
                        <SelectButton
                          setData={setDepartment}
                          selected={department}
                          title={"Department"}
                          data={storesDepartmentsList}
                        />
                      </>
                    )}
                  </Box>
                  <DateSelector
                    setStartDate={setStartDate}
                    setEndDate={setEndDate}
                    endDate={endDate}
                    startDate={startDate}
                  />
                </Box>
              </>
            )}

            <Box sx={vendorSearchButtonNorderContainer}>
              <Button
                onClick={getTheDataBasedOnUserInput}
                variant="contained"
                color="success"
                sx={vendorSearchButton}
              >
                조회
              </Button>

              <ToastContainer />
              <Button
                onClick={linkToOrderPage}
                variant="contained"
                color="success"
                sx={{
                  ...vendorSearchButton,
                  marginLeft: "10px",
                }}
              >
                주문
              </Button>
            </Box>
          </Box>

          <Box>
            <VendorReportTable
              selectedData={selectedData}
              totalSalesNQt={totalSalesNQt}
              groupedData={groupedData}
              loading={prodInfoloading}
            />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default VendorReportPageComponent;
