import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import { Box } from "@mui/material";
import { fontBold } from "styles/style";
import Checkbox from "@mui/material/Checkbox";

import { caclulateTotalOrderAmount } from "./OrderPageUtils";
import CurrentPriceTextFiled from "./CurrentPriceTextFiled";
import NumberCounter from "./NumberCounter";

import { tableTopDownSize } from "styles/style";
import { flexCenterStyles } from "styles/style";
import { totalDataTextSize } from "styles/style";
import OrderPageExtendedTable from "./OrderPageExtendedTable";

const OrderProduct = (props) => {
  const { currentOrderTable, setCurrentOrderTable } = props;

  const [currentTotalA, setCurrentTotalA] = useState(0);
  const [currentTotalQ, setCurrentTotalQ] = useState(0);

  const largeToggleCheckbox = (titleIndex) => {
    setCurrentOrderTable((prevGroup) => {
      const updatedTitleIndex = prevGroup.map((category, idx) => {
        if (idx === titleIndex) {
          const currentSelectStatus = category.select;

          const updatedItems = category.items.map((item) => {
            return { ...item, select: currentSelectStatus ? false : true };
          });
          category.select = !currentSelectStatus;
          return { ...category, items: updatedItems };
        }

        return category;
      });

      return updatedTitleIndex;
    });
  };

  const smallToggleCheckbox = (titleIndex, index) => {
    setCurrentOrderTable((prevGroup) => {
      const updatedTitleIndex = prevGroup.map((category, idx) => {
        if (idx === titleIndex) {
          console.log("check", category.items[0]);
          category.items[index] = {
            ...category.items[index],
            select: !category.items[index].select,
          };
          return { ...category };
        }
        return category;
      });

      return updatedTitleIndex;
    });
  };
  const orderTableTitle = ["", "물품", "수량", "총가격", ""];

  return (
    <Box>
      {console.log(currentOrderTable)}
      <Box
        sx={{
          ...fontBold,

          textAlign: "center",
          fontSize: "30px",
          padding: "20px",
        }}
      >
        주문 페이지
      </Box>
      <TableContainer component={Paper}>
        <Table sx={{ borderCollapse: "separate", borderSpacing: 0 }}>
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>선택</TableCell>
              <TableCell>물품</TableCell>
              <TableCell>수량</TableCell>
              <TableCell>총가격</TableCell>
            </TableRow>
          </TableHead>
          {/* <TableBody
           
          > */}
          {Object.keys(currentOrderTable).length !== 0 &&
            Object.entries(currentOrderTable).map(([key, value], index) => (
              <>
                <TableBody key={key + index}>
                  {/* {console.log(value)} */}
                  <OrderPageExtendedTable
                    values={value}
                    titleIndex={index}
                    currentOrderTable={currentOrderTable}
                    setCurrentOrderTable={setCurrentOrderTable}
                    smallToggleCheckbox={smallToggleCheckbox}
                    largeToggleCheckbox={largeToggleCheckbox}
                  />
                </TableBody>
              </>
            ))}

          {/* </TableBody> */}
        </Table>
      </TableContainer>
      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <Box sx={totalDataTextSize}>Total Quantity: {currentTotalQ}</Box>
        <Box sx={totalDataTextSize}>
          Total Amount: {currentTotalA.toFixed(2)}
        </Box>
      </Box>
    </Box>
  );
};

export default OrderProduct;
