import DbManager from "globalApi/DbManager";
import { useEffect, useState } from "react";
import { insertDataIntoObject } from "./APUtils";
import { getOneYBeforeSameDay } from "utils/utils";
import { convertDateForm } from "utils/utils";

export const useSalesDetailsByStores = () => {
  const [data, setData] = useState({});
  const [isSalesDetailsByStoresLoading, setIsSalesDetailsByStoresLoading] =
    useState(true);

  useEffect(() => {
    const fetchData = async () => {
      setIsSalesDetailsByStoresLoading(true);
      const resp = await DbManager.GET("/achieve/salesDetails");

      setData({
        store: resp.map((item) => item.store.trim()),
        prevYearAverage: resp.map((item) => parseInt(item.target.trim(), 10)),
        currentYearAverage: resp.map((item) =>
          parseInt(item.achievement.trim(), 10)
        ),
      });
      setIsSalesDetailsByStoresLoading(false);
    };
    fetchData();
  }, []);
  return { data, isSalesDetailsByStoresLoading };
};

export const useGetTotalDailySalesByStores = (
  selectedDate,
  startTime,
  endTime,
  currentMonthSList,
  prevMonthSList,
  prevYearSList
) => {
  const [dailyTotalSales, setDailyTotalSales] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);

      if (
        currentMonthSList !== undefined &&
        prevMonthSList !== undefined &&
        prevYearSList !== undefined
      ) {
        const formattedDay = convertDateForm(selectedDate);

        const currentQueryString = JSON.stringify(currentMonthSList);
        const prevMQueryString = JSON.stringify(prevMonthSList);
        const prevYQueryString = JSON.stringify(prevYearSList);
        const startT = startTime.hour();
        const endT = endTime.hour();

        const [yesterDayTotalS, currentMonthAver, prevMonthAver, prevYearAver] =
          await Promise.all([
            DbManager.GET(
              `/achieve/stores/daily-total?date=${formattedDay}&startT=${startT}&endT=${endT}`
            ),
            DbManager.GET(
              `/achieve/stores/days-average?dateList=${currentQueryString}&startT=${startT}&endT=${endT}`
            ),
            DbManager.GET(
              `/achieve/stores/days-average?dateList=${prevMQueryString}&startT=${startT}&endT=${endT}`
            ),
            DbManager.GET(
              `/achieve/stores/days-average?dateList=${prevYQueryString}&startT=${startT}&endT=${endT}`
            ),
          ]);

        const updatedDataWithCPMPY = await insertDataIntoObject(
          yesterDayTotalS,
          currentMonthAver,
          prevMonthAver,
          prevYearAver
        );
        setDailyTotalSales(updatedDataWithCPMPY);
      }

      setIsLoading(false);
    };
    fetchData();
  }, [
    currentMonthSList,
    prevMonthSList,
    prevYearSList,
    selectedDate,
    startTime,
    endTime,
  ]);

  return { dailyTotalSales, isLoading };
};

export const useGetStoreDetailsInfo = (
  storeIntial,
  selectedDate,
  startTime,
  endTime
) => {
  const [currentStoredetailInfo, setCurrentStoredetailInfo] = useState({});
  const [lastYearStoreDetailInfo, setLastYearStoreDetailInfo] = useState({});
  const [storeDetailInfoIsLoading, setStoreDetailInfoIsLoading] =
    useState(true);

  const formattedDay = convertDateForm(selectedDate);
  const startT = startTime.hour();
  const endT = endTime.hour();

  useEffect(() => {
    const getTheDetailPageStoreInfo = async () => {
      if (storeIntial !== undefined && selectedDate !== undefined) {
        const sameDay = getOneYBeforeSameDay(selectedDate);

        const [currentStoredetailInfo, lastYearStoreDetailInfo] =
          await Promise.all([
            DbManager.GET(
              `/achieve/store/details?storeIntial=${storeIntial}&selectedDate=${selectedDate}&startT=${startT}&endT=${endT}`
            ),
            DbManager.GET(
              `/achieve/store/details?storeIntial=${storeIntial}&selectedDate=${sameDay}&startT=${startT}&endT=${endT}`
            ),
          ]);
        setCurrentStoredetailInfo(currentStoredetailInfo);
        setLastYearStoreDetailInfo(lastYearStoreDetailInfo);
        setStoreDetailInfoIsLoading(false);
      }
    };
    getTheDetailPageStoreInfo();
  }, [selectedDate, startT, endT, storeIntial]);

  return {
    currentStoredetailInfo,
    lastYearStoreDetailInfo,
    storeDetailInfoIsLoading,
  };
};
