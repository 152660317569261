import {
  Box,
  Button,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableHead,
  Typography,
} from "@mui/material";
import React from "react";
import { orderConfirmModalStyle } from "styles/style";

const OrderConfirmModal = (props) => {
  const {
    currentOrderTable,
    modalOpen,
    setModalOpen,
    setExportExcelHandlerState,
  } = props;

  const handleOpen = () => setModalOpen(true);
  const handleClose = () => setModalOpen(false);

  const orderConfirmeEvent = (e) => {
    e.preventDefault();
    handleClose();
    setExportExcelHandlerState(true);
  };
  return (
    <Box>
      <Button onClick={handleOpen}>Open modal</Button>
      <Modal
        keepMounted
        open={modalOpen}
        onClose={handleClose}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box
          sx={{ ...orderConfirmModalStyle, overflowY: "auto", height: "500px" }}
        >
          <Typography id="keep-mounted-modal-title" variant="h6" component="h2">
            입력하신 정보가 정확한지 확인해주세요. 맞으신가요?
            <Table
              sx={{
                gap: 5,
                fontSize: "8px",
              }}
            >
              <TableHead>
                <TableCell>아이템종류</TableCell>
                <TableCell align="right">아이템갯수</TableCell>
                <TableCell align="center">총합</TableCell>
              </TableHead>
              {Object.entries(currentOrderTable).map(([key, value]) => (
                <>
                  <TableBody>
                    <TableCell sx={{ paddingBottom: 0, paddingTop: 0 }}>
                      {value.pName}
                    </TableCell>

                    <TableCell
                      align="center"
                      sx={{ paddingBottom: 0, paddingTop: 0 }}
                    >
                      {value.totalQuantity}
                    </TableCell>

                    <TableCell
                      align="right"
                      sx={{ paddingBottom: 0, paddingTop: 0 }}
                    >
                      ${value.totalAmount.toFixed(2)}
                    </TableCell>
                  </TableBody>
                </>
              ))}
            </Table>
          </Typography>
          <Button onClick={(e) => orderConfirmeEvent(e)}>확인</Button>
          <Button onClick={() => handleClose()}>아니요</Button>
        </Box>
      </Modal>
    </Box>
  );
};

export default OrderConfirmModal;
