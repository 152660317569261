// import { TableRow } from "@mui/material";
// import { styled } from "@mui/material/styles";
// import TableCell, { tableCellClasses } from "@mui/material/TableCell";

export const vendorReportTitleInfoStyles = {
  display: "flex",
  justifyContent: "space-between",
  gap: 3,
  flexDirection: "column",
};

export const vendorReportHeader = {
  display: "flex",
  fontWeight: "bold",
  justifyContent: "flex-end",
  fontSize: "14px",
  marginRight: "80px",
  gap: 5,
  marginBottom: "30px",
};

export const venderTableTitle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-around",
  fontSize: "20px",
  marginBottom: "10px",
  marginTop: "20px",
};

export const tableBoxShawdow = {
  boxShadow: "2px 4px 10px 1px rgba(0, 0, 0, 0.24)",
};

export const vendorReportContainerBox = {
  position: "absolute",
  display: "flex",

  flexDirection: "column",
  left: 0,
  right: 0,

  marginTop: "120px ",
};

export const vendorReportContainer = {
  marginLeft: "auto",
  marginRight: "auto",
  display: "flex",
};

export const vendorButton = {
  display: "flex",
  gap: 2,
};

export const vendorSearchButtonNorderContainer = {
  marginLeft: "15px",
  display: "flex",
  justifyContent: "space-evenly",
  width: "200px",
};

export const vendorSearchButton = {
  backgroundColor: "#129d58",
  width: "6vw",
  borderRadius: "10px",
};
