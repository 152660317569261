import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

import { Box } from "@mui/material";
import { createArrayWithOneItemFromObject } from "layouts/main/MainPageUtils";
import { chartWapper } from "layouts/main/MainPageStyle";
import { chartContainder } from "layouts/main/MainPageStyle";
import { ChartOption } from "utils/ChartOption";
import { createChartDataForm } from "utils/ChartOption";
// import { chartContainder, chartWapper } from "./MainPageStyle";
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);
const MainPageDetailsChart = ({ currentNLastYearStoreDetailInfo }) => {
  const department = Object.keys(currentNLastYearStoreDetailInfo).map(
    (key, value) => currentNLastYearStoreDetailInfo[key].ptName
  );
  const currentDayTotalByDepart = createArrayWithOneItemFromObject(
    currentNLastYearStoreDetailInfo,
    "total"
  );
  const lastYearDayTotalByDepart = createArrayWithOneItemFromObject(
    currentNLastYearStoreDetailInfo,
    "lastYearTotal"
  );

  const chartData = createChartDataForm(
    department,
    currentDayTotalByDepart,
    lastYearDayTotalByDepart
  );

  return (
    <Box sx={chartWapper}>
      <Box sx={chartContainder}>
        <Bar data={chartData} options={ChartOption} height={400} />
      </Box>
    </Box>
  );
};

export default MainPageDetailsChart;
