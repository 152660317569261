import { Box, Button, Menu } from "@mui/material";
import { DigitalClock, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import React, { useState } from "react";
const TimePickerComponent = ({ time, setTime, compareTime, isStart }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const timePickerEventStart = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const timePickerEventClose = (event) => {
    setAnchorEl(null);
  };
  const handleTimeChange = (newTime) => {
    if (isStart && newTime > compareTime) {
      alert("Start time cannot be later than end time");
      return;
    }

    if (!isStart && newTime < compareTime) {
      alert("End time cannot be earlier than start time");
      return;
    }
    setTime(newTime);
    timePickerEventClose();
  };
  return (
    <Box>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Button
          variant="outlined"
          onClick={timePickerEventStart}
          sx={{ width: "100px", height: "57px", marginTop: "8px" }}
        >
          {time ? time.format("HH:mm") : "Select Time"}
        </Button>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={timePickerEventClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <DigitalClock timeStep={60} onChange={handleTimeChange} />
        </Menu>
      </LocalizationProvider>
    </Box>
  );
};

export default TimePickerComponent;
