import { STORE } from "globalApi/EnumType";
import { createArrayWithOneItemFromObject } from "./MainPageUtils";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

import { Box } from "@mui/material";
import { chartContainder, chartWapper } from "./MainPageStyle";
import { ChartOption } from "utils/ChartOption";
import { createChartDataForm } from "utils/ChartOption";
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

/* 
  메인 페이지에 보여지는 차트를 그리는 컴포넌트
*/

const MainPageChart = ({ dailyTotalSales }) => {
  const store = Object.keys(dailyTotalSales).map(
    (key) => STORE[parseInt(key)][0]
  );

  const currentMAverage = createArrayWithOneItemFromObject(
    dailyTotalSales,
    "currentMAverage"
  );

  const lastYearAverage = createArrayWithOneItemFromObject(
    dailyTotalSales,
    "lastYearAverage"
  );

  const chartData = createChartDataForm(
    store,
    currentMAverage,
    lastYearAverage
  );

  return (
    <Box sx={chartWapper}>
      <Box sx={chartContainder}>
        <Bar data={chartData} options={ChartOption} height={400} />
      </Box>
    </Box>
  );
};

export default MainPageChart;
