import React, { useEffect, useState } from "react";
import OrderProduct from "./OrderProduct";
import { useLocation } from "react-router-dom";

import { Avatar, Box, Button } from "@mui/material";
import EmailSender from "utils/EmailSender";

import OrderedProdctExcel from "utils/OrderedProdctExcel";
import excelImage from "../../img/excel32.png";
import SideNavbar from "layouts/sidenavbar/SideNavbar";
import OrderConfirmModal from "./OrderConfirmModal";

/* 
  주문페이지 관련된 컴포넌트
*/
const OrderPage = () => {
  const [currentOrderTable, setCurrentOrderTable] = useState({});
  const location = useLocation();
  const data = location.state;
  const [modalOpen, setModalOpen] = useState(false);
  const [exportExcelHandlerState, setExportExcelHandlerState] = useState(false);
  useEffect(() => {
    if (data) {
      const addSelectSectiontoCurrentOrderItems = Object.entries(data).map(
        ([key, value]) => {
          const updatedValue = { ...value, select: true };

          const updatedItems = Object.values(value.items).map((item) => ({
            ...item,
            select: true,
          }));
          // console.log("updatedItem", updatedItems);
          return { ...updatedValue, items: updatedItems };
        }
      );

      setCurrentOrderTable(addSelectSectiontoCurrentOrderItems);
    }
  }, [data]);

  const exportExcelHandler = () => {
    // console.log(currentOrderTable);
    OrderedProdctExcel({ data: currentOrderTable });
  };

  const orderconfirmAlert = () => {
    setModalOpen(true);
  };

  return (
    <Box>
      {currentOrderTable === false ? (
        <></>
      ) : (
        <>
          {/* {console.log("data", data)} */}
          <SideNavbar />
          <Box>
            <OrderProduct
              currentOrderTable={currentOrderTable}
              setCurrentOrderTable={setCurrentOrderTable}
            />
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                marginRight: "50px",
              }}
            >
              <Button
                variant="contained"
                sx={{
                  height: "50px",
                  borderRadius: "10px",
                  marginBottom: "20px",
                  marginTop: "10px",
                }}
                onClick={() => orderconfirmAlert(currentOrderTable)}
              >
                주문확인서제출
              </Button>
              {exportExcelHandlerState && (
                <Box onClick={exportExcelHandler}>
                  <Avatar
                    alt="Excel Icon"
                    src={excelImage}
                    sx={{ width: 70, height: 70, cursor: "pointer" }}
                  />
                </Box>
              )}
            </Box>
          </Box>
          {modalOpen && (
            <Box>
              <OrderConfirmModal
                currentOrderTable={currentOrderTable}
                modalOpen={modalOpen}
                setModalOpen={setModalOpen}
                setExportExcelHandlerState={setExportExcelHandlerState}
              />
            </Box>
          )}
        </>
      )}
    </Box>
  );
};

export default OrderPage;
