import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Box } from "@mui/material";

import { flexAlignCenter } from "styles/style";

const DatePickerComponent = ({ date, setSelectedDate }) => {
  const handleDateChange = (newValue) => {
    setSelectedDate(newValue);
  };
  return (
    <Box sx={{ ...flexAlignCenter, gap: 2, marginLeft: "20px" }}>
      <Box>Date</Box>
      <Box>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DemoContainer components={["DatePicker"]}>
            <DatePicker
              label="Date picker"
              value={date}
              onChange={handleDateChange}
              dateFormat="yyyy-MM-dd"
              slotProps={{
                textField: {
                  sx: { width: 20 }, // 넓이 조정
                  InputProps: { style: { fontSize: 14 } }, // 폰트 크기
                },
              }}
            />
          </DemoContainer>
        </LocalizationProvider>
      </Box>
    </Box>
  );
};

export default DatePickerComponent;
