export const useCombineTwoObBasedOnCurrentStore = (
  currentStoredetailInfo,
  lastYearStoreDetailInfo
) => {
  if (
    Object.keys(currentStoredetailInfo)?.length !== 0 &&
    Object.keys(lastYearStoreDetailInfo)?.length !== 0
  ) {
    const combinedData = currentStoredetailInfo.map((data) => {
      const matchingLastYearItem = lastYearStoreDetailInfo.find(
        (lastYearTime) => lastYearTime.ptName === data.ptName
      );

      return {
        ...data,
        lastYearTotal: matchingLastYearItem ? matchingLastYearItem.total : 0,
      };
    });

    return { currentNLastYearStoreDetailInfo: combinedData };
  }
};
