import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { STORE } from "../../globalApi/EnumType";

import { StyledTableCell, StyledTableRow } from "./MainPageStyle";
import { tableBoxShawdow } from "layouts/vendorReport/VendorReportPageStyle";
import { useNavigate } from "react-router-dom";
import { useCalculateAllTotal } from "hooks/hook";

const MainPageComparisonTable = ({
  dailyTotalSales,
  selectedDate,
  startTime,
  endTime,
}) => {
  const navigate = useNavigate();

  const comparisonTable = [
    "매장",
    "금일 매출",
    "당월 평균",
    "전년 평균",
    "전월 평균",
    "당월 평균 대비",
    "전년 평균 대비",
    "전월 평균 대비",
  ];

  const { calculatedTotals } = useCalculateAllTotal({ dailyTotalSales });

  const moveToDetailPage = (storeIntial) => {
    const dateNTimeInfo = {
      date: selectedDate.format("YYYY-MM-DD"),
      startT: startTime.format("HH:mm"),
      endT: endTime.format("HH:mm"),
    };

    navigate(`/details/${storeIntial}`, { state: dateNTimeInfo });
  };
  return (
    <TableContainer
      component={Paper}
      sx={{
        ...tableBoxShawdow,
        ...comparisonTable,
      }}
    >
      <Table
        sx={{
          minWidth: 320,
          "& th, & td": {
            fontSize: { xs: "12px", sm: "14px", md: "16px" },
            padding: { xs: "6px", sm: "8px", md: "12px" },
          },
        }}
      >
        <TableHead>
          <TableRow>
            {comparisonTable.map((title) => (
              <StyledTableCell
                key={title}
                align="center"
                isFixed={title === comparisonTable[0]}
              >
                {title}
              </StyledTableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.entries(dailyTotalSales).map(([key, value]) => (
            <StyledTableRow
              key={key}
              sx={{
                cursor: "pointer",
                "&:hover": {
                  backgroundColor: "#ff9800",
                },
              }}
              onClick={() => moveToDetailPage(STORE[key][1])}
            >
              <StyledTableCell align="center" isFixed={true}>
                {STORE[key][0]}
              </StyledTableCell>
              {Object.entries(value).map(([subKey, subValue]) => (
                <StyledTableCell key={subKey} align="center">
                  $
                  {subValue.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </StyledTableCell>
              ))}
            </StyledTableRow>
          ))}
          <TableRow>
            {calculatedTotals && (
              <>
                <StyledTableCell align="center" isFixed={true}>
                  합계
                </StyledTableCell>
                {Object.entries(calculatedTotals).map(([key, value]) => (
                  <StyledTableCell key={key + value} align="center">
                    $
                    {value.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </StyledTableCell>
                ))}
              </>
            )}
            {/* 
            {Object.entries(calculatedTotals).map(([key, value]) => (
              <StyledTableCell align="center">
                {value}.toFiexd(2)
              </StyledTableCell>
            ))} */}
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default MainPageComparisonTable;
